<template>
  <div>
    <p class="font-weight-bold">1. ご自宅(対面)レッスンかオンラインかを選択ください</p>
    <v-radio-group
      v-model="application.value.lessonType"
      v-on:change="updateLessonType"
      row
    >
      <v-radio
        label="ご自宅(対面)"
        value="offline"
      ></v-radio>
      <v-radio
        label="オンライン"
        value="online"
      ></v-radio>
    </v-radio-group>
  </div>
</template>

<script lang="ts">
  import { useApplication, updateLessonType } from '@/models/application'

  export default {
    setup () {
      const { application } = useApplication();
      return {application, updateLessonType}
    }
  }
</script>
