<template>
  <v-app-bar
      app
      color=""
      light
    >
      <div class="d-flex align-center">
        <a href="https://omsister.com">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="/logo_header.png"
            transition="scale-transition"
            width="170"
          />
        </a>
      </div>

      <v-spacer></v-spacer>

 
    </v-app-bar>
</template>

<script>
  export default {
    data: () => ({
     
    }),
  }
</script>
