<template>
  <div>
     <p>5.  ご利用希望のオプションを選択してください</p>

    <v-checkbox
        v-model="application.value.eiken"
        v-if="application.value.course !== 'international'"
        :label="`英検オプションプラン`"
        class="mt-0"
      ></v-checkbox>

      <p v-if="application.value.lessonType === 'online' && application.value.course === 'international'">選択できるオプションはありません</p>
  </div>
</template>

<script>
  import { useApplication } from '@/models/application'

  export default {
    setup () {
      const { application } = useApplication();
      return {application}
    }
  }
</script>
